<template>
  <div class="main__content members">
    <div class="members__control">
      <div class="members__search-select">
        <InputSearch
          isFilter
          class="members__control-item members__control_search"
          @detectSearchValue="searchTableHandler"
        />
        <SelectField
          v-model="getSelectedAction"
          titleKey="action"
          valueToReturn="object"
          isObjectType
          isMultiSelect
          :items="
            !!getOptionsOfLogsAccessAdmin ? getOptionsOfLogsAccessAdmin : []
          "
          :isDisabled="!getOptionsOfLogsAccessAdmin"
          placeholder="Оберіть роль"
          class="members__control-item members__control_select"
        />
      </div>
    </div>
    <Table
      v-if="!!getTableData"
      :content="getTableData"
      :hasTooltip="false"
      @tableHandler="tableHandler"
    />
    <v-skeleton-loader v-else type="table" />
  </div>
</template>

<script>
import Table from '@/elements/Table';
import SelectField from '@/elements/SelectField.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    Table,
    SelectField,
  },

  data() {
    return {
      selectedActions: [],
    };
  },

  async created() {
    await this.$store.dispatch('getEventLogActionsAccessAdmin');
    await this.$store.dispatch('getEventLogTabelAccessAdmin');
  },

  computed: {
    ...mapGetters(['getTableOfLogsAccessAdmin', 'getOptionsOfLogsAccessAdmin']),

    getTableData() {
      return this.getTableOfLogsAccessAdmin?.table?.items;
    },

    getSelectedAction: {
      get() {
        return this.selectedActions;
      },
      set(val) {
        if (!!!val.length) {
          this.$store.commit('SET_TABLE_SORT_DATA', { page: 1 });
        }
        this.selectedActions = [...val];
        this.$store.commit(
          'SET_SELECTED_ACTIONS_ACCESS_ADMIN',
          this.selectedActions?.map((el) => el.action)
        );
        this.$store.dispatch('getEventLogTabelAccessAdmin');
      },
    },
  },

  methods: {
    tableHandler() {
      this.$store.dispatch('getEventLogTabelAccessAdmin');
    },

    searchTableHandler(val) {
      this.$store.commit('SET_TABLE_SORT_DATA', { search: val });
      this.$store.dispatch('getEventLogTabelAccessAdmin');
    },
  },
};
</script>

<style lang="sass" scoped>
.members__control
  display: flex
  justify-content: space-between
  padding: 5rem 0
  align-items: center
  position: relative
  z-index: 10
  @include xs
    padding: 3rem 0 4rem 0

.members__search-select
  display: flex
  align-items: baseline
  @include s
  @include xs
    flex-direction: column
    align-items: start
    padding: 0

.members__control-item
  width: 300px

.members__control_search
  margin-right: 40px
  @include xs
    margin-right: 0

.members__control_select
  transform: translateY(3px)
  @include xs
    margin-top: 20px
</style>
